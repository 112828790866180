import React, { useRef, useState, useEffect, useCallback, Dispatch, SetStateAction, ReactNode } from 'react'
import { createChart, IChartApi } from 'lightweight-charts'
import { RowBetween } from 'components/Row'
import Card from '../Card'
import styled from 'styled-components'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import useTheme from 'hooks/useTheme'

dayjs.extend(utc)

const Wrapper = styled(Card)`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  > * {
    font-size: 1rem;
  }
  background-color: ${({ theme }) => theme.bg0};
`

const DEFAULT_HEIGHT = 300

export type LineChartProps = {
  data: any[]
  color?: string | undefined
  height?: number | undefined
  minHeight?: number
  setValue?: Dispatch<SetStateAction<number | undefined>> // used for value on hover
  setLabel?: Dispatch<SetStateAction<string | undefined>> // used for value label on hover
  topLeft?: ReactNode | undefined
  topRight?: ReactNode | undefined
  bottomLeft?: ReactNode | undefined
  bottomRight?: ReactNode | undefined
} & React.HTMLAttributes<HTMLDivElement>

const CandleChart = ({
  data,
  color = '#56B2A4',
  setValue,
  setLabel,
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
  height = DEFAULT_HEIGHT,
  minHeight = DEFAULT_HEIGHT,
  ...rest
}: LineChartProps) => {
  const theme = useTheme()
  const textColor = theme?.text3
  const chartRef = useRef<HTMLDivElement>(null)
  const chartInstanceRef = useRef<IChartApi | null>(null)
  const seriesRef = useRef<any>(null)

  const handleResize = useCallback(() => {
    if (chartInstanceRef.current && chartRef?.current?.parentElement) {
      chartInstanceRef.current.resize(chartRef.current.parentElement.clientWidth - 32, height)
      chartInstanceRef.current.timeScale().fitContent()
      chartInstanceRef.current.timeScale().scrollToPosition(0, false)
    }
  }, [height])

  // Cleanup function
  const cleanupChart = useCallback(() => {
    if (chartInstanceRef.current) {
      // First remove the series if it exists
      if (seriesRef.current) {
        try {
          chartInstanceRef.current.removeSeries(seriesRef.current)
        } catch (e) {
          console.error('Error removing series:', e)
        }
        seriesRef.current = null
      }

      // Then remove the chart
      try {
        chartInstanceRef.current.remove()
      } catch (e) {
        console.error('Error removing chart:', e)
      }
      chartInstanceRef.current = null
    }
  }, [])

  useEffect(() => {
    const resizeListener = () => handleResize()
    window.addEventListener('resize', resizeListener)
    return () => window.removeEventListener('resize', resizeListener)
  }, [handleResize])

  useEffect(() => {
    // Clean up existing chart first
    cleanupChart()

    // Create new chart only if we have data and a container
    if (data && data.length > 0 && chartRef.current) {
      chartInstanceRef.current = createChart(chartRef.current, {
        height,
        width: chartRef.current.parentElement ? chartRef.current.parentElement.clientWidth - 32 : 0,
        layout: {
          backgroundColor: 'transparent',
          textColor: textColor || '#565A69',
          fontFamily: 'Inter var',
        },
        rightPriceScale: {
          scaleMargins: {
            top: 0.1,
            bottom: 0.1,
          },
          borderVisible: false,
        },
        timeScale: {
          borderVisible: false,
          secondsVisible: true,
          tickMarkFormatter: (unixTime: number) => {
            return dayjs.unix(unixTime).format('MM/DD h:mm A')
          },
        },
        watermark: {
          visible: false,
        },
        grid: {
          horzLines: {
            visible: false,
          },
          vertLines: {
            visible: false,
          },
        },
        crosshair: {
          horzLine: {
            visible: false,
            labelVisible: false,
          },
          mode: 1,
          vertLine: {
            visible: true,
            labelVisible: false,
            style: 3,
            width: 1,
            color: '#505050',
            labelBackgroundColor: color,
          },
        },
      })

      chartInstanceRef.current.timeScale().fitContent()

      const series = chartInstanceRef.current.addCandlestickSeries({
        upColor: 'green',
        downColor: 'red',
        borderDownColor: 'red',
        borderUpColor: 'green',
        wickDownColor: 'red',
        wickUpColor: 'green',
      })

      seriesRef.current = series
      series.setData(data)

      // Subscribe to crosshair move
      chartInstanceRef.current.subscribeCrosshairMove((param) => {
        if (
          chartRef?.current &&
          (param === undefined ||
            param.time === undefined ||
            (param && param.point && param.point.x < 0) ||
            (param && param.point && param.point.x > chartRef.current.clientWidth) ||
            (param && param.point && param.point.y < 0) ||
            (param && param.point && param.point.y > height))
        ) {
          setValue && setValue(undefined)
          setLabel && setLabel(undefined)
        } else if (series && param) {
          const timestamp = param.time as number
          const time = dayjs.unix(timestamp).utc().format('MMM D, YYYY h:mm A') + ' (UTC)'
          const parsed = param.seriesPrices.get(series) as { open: number } | undefined
          setValue && setValue(parsed?.open)
          setLabel && setLabel(time)
        }
      })
    }

    // Cleanup on unmount or when dependencies change
    return () => {
      cleanupChart()
    }
  }, [color, data, height, setValue, setLabel, textColor, cleanupChart])

  return (
    <Wrapper minHeight={minHeight}>
      <RowBetween>
        {topLeft ?? null}
        {topRight ?? null}
      </RowBetween>
      <div ref={chartRef} id={'candle-chart'} {...rest} />
      <RowBetween>
        {bottomLeft ?? null}
        {bottomRight ?? null}
      </RowBetween>
    </Wrapper>
  )
}

export default React.memo(CandleChart)
