import BERACHAIN_LOGO_URL from '../assets/images/berachain-logo.png'
import { SupportedChainId } from 'constants/chains'


export enum SupportedNetwork {
  BERACHAIN_BARTIO_TESTNET,
  // BERACHAIN_CARTIO_TESTNET,
  BERACHAIN,
}

export type NetworkInfo = {
  chainId: number
  id: SupportedNetwork
  route: string
  name: string
  imageURL: string
  bgColor: string
  primaryColor: string
  secondaryColor: string
}

console.log(SupportedChainId)

export const BerachainBArtioTestnetNetworkInfo: NetworkInfo = {
  chainId: SupportedChainId.BERACHAIN_BARTIO_TESTNET,
  id: SupportedNetwork.BERACHAIN_BARTIO_TESTNET,
  route: 'berachain_bartio',
  name: 'Berachain bArtio',
  bgColor: '#1E1B19',
  primaryColor: '#3083DC',
  secondaryColor: '#2172e5',
  imageURL: BERACHAIN_LOGO_URL,
}

// export const BerachainCartioTestnetNetworkInfo: NetworkInfo = {
//   chainId: SupportedChainId.BERACHAIN_CARTIO_TESTNET,
//   id: SupportedNetwork.BERACHAIN_CARTIO_TESTNET,
//   route: 'berachain-cartio',
//   name: 'Berachain cArtio',
//   bgColor: '#1E1B19',
//   primaryColor: '#3083DC',
//   secondaryColor: '#2172e5',
//   imageURL: BERACHAIN_LOGO_URL,
// }

export const BerachainNetworkInfo: NetworkInfo = {
  chainId: SupportedChainId.BERACHAIN,
  id: SupportedNetwork.BERACHAIN,
  route: 'berachain_mainnet',
  name: 'Berachain',
  bgColor: '#1E1B19',
  primaryColor: '#3083DC',
  secondaryColor: '#2172e5',
  imageURL: BERACHAIN_LOGO_URL,
}

export const SUPPORTED_NETWORK_VERSIONS: NetworkInfo[] = [
  BerachainBArtioTestnetNetworkInfo,
  BerachainNetworkInfo,
]
