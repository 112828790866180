import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import TokenPage from './TokenPage'
import { isAddress } from 'ethers/lib/utils'

export function RedirectInvalidToken() {
  const { address } = useParams<{ address: string }>()

  if (!isAddress(address ?? '')) {
    return <Navigate to="/" replace />
  }
  return <TokenPage />
}
