import { NetworkInfo } from 'constants/networks'

export function networkPrefix(activeNetwork: NetworkInfo) {
  const prefix = '/' + activeNetwork.route.toLocaleLowerCase() + '/'
  return prefix
}

export function getTokenLink(activeNetwork: NetworkInfo, tokenAddress: string) {
  return '/' + activeNetwork.route.toLowerCase() + '/tokens/' + tokenAddress
}

export function getPoolLink(activeNetwork: NetworkInfo, poolAddress: string) {
  return '/' + activeNetwork.route.toLowerCase() + '/pools/' + poolAddress
}
