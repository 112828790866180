import { useClients } from 'state/application/hooks'
import gql from 'graphql-tag'
import { useEffect, useMemo, useState } from 'react'

// TODO: Berizard - how to get the synced vs head block
export const SUBGRAPH_META = gql`
  query Metadata {
    _meta {
      block {
        hash
        number
        parentHash
        timestamp
      }
      deployment
      hasIndexingErrors
    }
  }
`

interface HealthResponse {
  _meta: {
    block: {
      hash: string
      number: number
      parentHash: string
      timestamp: number
    }
    deployment: string
    hasIndexingErrors: boolean
  }
}

/**
 * Fetch top addresses by volume
 */
export function useFetchedSubgraphStatus(): {
  available: boolean | null
  syncedBlock: number | undefined
  headBlock: number | undefined
} {
  const { dataClient } = useClients()
  const [result, setResult] = useState<HealthResponse | null>(null)
  useEffect(() => {
    async function fetchData() {
      const result = await dataClient.query<HealthResponse>({
        query: SUBGRAPH_META
      })
      setResult(result.data)
    }
    fetchData()
  }, [dataClient])

  const { available, syncedBlock, headBlock } = useMemo(() => {
    if (!result || !result._meta) {
      return {
        available: false,
        syncedBlock: undefined,
        headBlock: undefined,
      }
    }

    const blockNumber = result._meta.block.number

    return {
      available: true,
      syncedBlock: blockNumber,
      headBlock: blockNumber,
    }
  }, [result])

  return {
    available,
    syncedBlock,
    headBlock,
  }
}
