import { getPercentChange } from '../../utils/data'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useDeltaTimestamps } from 'utils/queries'
import { useBlocksFromTimestamps } from 'hooks/useBlocksFromTimestamps'
import { get2DayChange } from 'utils/data'
import { TokenData } from 'state/tokens/reducer'
import { useEthPrices } from 'hooks/useEthPrices'
import { formatTokenSymbol, formatTokenName } from 'utils/tokens'
import { useActiveNetworkVersion, useClients } from 'state/application/hooks'
import { useMemo } from 'react'

export const TOKENS_BULK = (block: number | undefined) => {
  return gql`
    query tokens($tokens: [String!]!) {
      tokens(
        where: { id_in: $tokens }
        ${block ? `block: { number: ${block} }` : ''}
        orderBy: totalValueLockedUSD
        orderDirection: desc
        subgraphError: allow
      ) {
        id
        symbol
        name
        derivedETH
        volumeUSD
        volume
        txCount
        totalValueLocked
        feesUSD
        totalValueLockedUSD
      }
    }
  `
}

interface TokenFields {
  id: string
  symbol: string
  name: string
  derivedETH: string
  volumeUSD: string
  volume: string
  feesUSD: string
  txCount: string
  totalValueLocked: string
  totalValueLockedUSD: string
}

interface TokenDataResponse {
  tokens: TokenFields[]
  // bundles: {
  //   ethPriceUSD: string
  // }[]
}

/**
 * Fetch top addresses by volume
 */
export function useFetchedTokenDatas(tokenAddresses: string[]): {
  loading: boolean
  error: boolean
  data:
  | {
    [address: string]: TokenData
  }
  | undefined
} {
  const [activeNetwork] = useActiveNetworkVersion()
  const { dataClient } = useClients()
  const ethPrices = useEthPrices()

  // get blocks from historic timestamps
  const [t24, t48, tWeek] = useDeltaTimestamps()
  const { blocks } = useBlocksFromTimestamps([t24, t48, tWeek])
  const [block24, block48, blockWeek] = blocks ?? []

  // Separate data fetching states
  const currentPeriod = useQuery<TokenDataResponse>(TOKENS_BULK(undefined), {
    client: dataClient,
    variables: { tokens: tokenAddresses },
    fetchPolicy: 'network-only',
    skip: tokenAddresses.length === 0
  })

  const period24 = useQuery<TokenDataResponse>(
    TOKENS_BULK(block24?.number ? parseInt(block24.number) : undefined),
    {
      client: dataClient,
      variables: { tokens: tokenAddresses },
      fetchPolicy: 'network-only',
      skip: tokenAddresses.length === 0 || !block24?.number
    }
  )

  const period48 = useQuery<TokenDataResponse>(
    TOKENS_BULK(block48?.number ? parseInt(block48.number) : undefined),
    {
      client: dataClient,
      variables: { tokens: tokenAddresses },
      fetchPolicy: 'network-only',
      skip: tokenAddresses.length === 0 || !block48?.number
    }
  )

  const periodWeek = useQuery<TokenDataResponse>(
    TOKENS_BULK(blockWeek?.number ? parseInt(blockWeek.number) : undefined),
    {
      client: dataClient,
      variables: { tokens: tokenAddresses },
      fetchPolicy: 'network-only',
      skip: tokenAddresses.length === 0 || !blockWeek?.number
    }
  )

  // Log non-critical errors but continue processing
  if (period24.error) {
    console.warn("Error fetching 24h token data:", period24.error)
  }
  if (period48.error) {
    console.warn("Error fetching 48h token data:", period48.error)
  }
  if (periodWeek.error) {
    console.warn("Error fetching week token data:", periodWeek.error)
  }

  const anyLoading = Boolean(
    currentPeriod.loading ||
    period24.loading ||
    period48.loading ||
    periodWeek.loading ||
    !blocks
  )
  // Process available data with useMemo
  const parsed = useMemo(() => {
    return currentPeriod.data?.tokens?.reduce((accum: { [address: string]: TokenFields }, tokenData) => {
      accum[tokenData.id] = tokenData
      return accum
    }, {}) ?? {}
  }, [currentPeriod.data])
  if (Object.keys(parsed).length > 0) {
  }

  const parsed24 = useMemo(() => {
    return period24.data?.tokens?.reduce((accum: { [address: string]: TokenFields }, tokenData) => {
      accum[tokenData.id] = tokenData
      return accum
    }, {}) ?? {}
  }, [period24.data])
  if (Object.keys(parsed24).length > 0) {
  }

  const parsed48 = useMemo(() => {
    return period48.data?.tokens?.reduce((accum: { [address: string]: TokenFields }, tokenData) => {
      accum[tokenData.id] = tokenData
      return accum
    }, {}) ?? {}
  }, [period48.data])
  if (Object.keys(parsed48).length > 0) {
  }

  const parsedWeek = useMemo(() => {
    return periodWeek.data?.tokens?.reduce((accum: { [address: string]: TokenFields }, tokenData) => {
      accum[tokenData.id] = tokenData
      return accum
    }, {}) ?? {}
  }, [periodWeek.data])
  if (Object.keys(parsedWeek).length > 0) {
  }

  // Format data and calculate changes with fallbacks
  const formatted = useMemo(() => {
    return tokenAddresses.reduce((accum: { [address: string]: TokenData }, address) => {
      const current: TokenFields | undefined = parsed[address]
      if (!current) return accum // Skip if no current data

      const oneDay: TokenFields | undefined = parsed24[address]
      const twoDay: TokenFields | undefined = parsed48[address]
      const week: TokenFields | undefined = parsedWeek[address]

      // Calculate volume changes with fallback values
      const [volumeUSD, volumeUSDChange] =
        current && oneDay && twoDay
          ? get2DayChange(current.volumeUSD, oneDay.volumeUSD, twoDay.volumeUSD)
          : [parseFloat(current.volumeUSD), 0]

      const volumeUSDWeek =
        current && week
          ? parseFloat(current.volumeUSD) - parseFloat(week.volumeUSD)
          : parseFloat(current.volumeUSD)

      const tvlUSD = parseFloat(current.totalValueLockedUSD)
      const tvlUSDChange = getPercentChange(current.totalValueLockedUSD, oneDay?.totalValueLockedUSD)
      const tvlToken = parseFloat(current.totalValueLocked)

      const priceUSD = ethPrices ? parseFloat(current.derivedETH) * ethPrices.current : 0
      const priceUSDOneDay = oneDay && ethPrices ? parseFloat(oneDay.derivedETH) * ethPrices.oneDay : 0
      const priceUSDWeek = week && ethPrices ? parseFloat(week.derivedETH) * ethPrices.week : 0

      const priceUSDChange = priceUSD && priceUSDOneDay
        ? getPercentChange(priceUSD.toString(), priceUSDOneDay.toString())
        : 0

      const priceUSDChangeWeek = priceUSD && priceUSDWeek
        ? getPercentChange(priceUSD.toString(), priceUSDWeek.toString())
        : 0

      const txCount = oneDay
        ? parseFloat(current.txCount) - parseFloat(oneDay.txCount)
        : parseFloat(current.txCount)

      const feesUSD = oneDay
        ? parseFloat(current.feesUSD) - parseFloat(oneDay.feesUSD)
        : parseFloat(current.feesUSD)

      accum[address] = {
        exists: true,
        address,
        name: formatTokenName(address, current.name),
        symbol: formatTokenSymbol(address, current.symbol),
        volumeUSD,
        volumeUSDChange,
        volumeUSDWeek,
        txCount,
        tvlUSD,
        feesUSD,
        tvlUSDChange,
        tvlToken,
        priceUSD,
        priceUSDChange,
        priceUSDChangeWeek,
      }

      return accum
    }, {})
  }, [parsed, parsed24, parsed48, parsedWeek, tokenAddresses, ethPrices])


  // Return early if no ETH prices or current period data
  if (!ethPrices || currentPeriod.error || !currentPeriod.data) {
    return {
      loading: !ethPrices || currentPeriod.loading,
      error: Boolean(currentPeriod.error),
      data: undefined,
    }
  }
  return {
    loading: anyLoading,
    error: false, // We're handling errors gracefully now
    data: formatted,
  }
}
