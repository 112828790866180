import { ApolloClient, InMemoryCache } from '@apollo/client'

// ---------Bartio Testnet----------------
export const berachainBartioTestnetClient = new ApolloClient({
  uri: "https://api.goldsky.com/api/public/project_clpx84oel0al201r78jsl0r3i/subgraphs/kodiak-v3-berachain-bartio/latest/gn",
  cache: new InMemoryCache({
    typePolicies: {
      Token: {
        keyFields: false,
      },
      Pool: {
        keyFields: false,
      },
    },
  }),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export const berachainBartioTestnetBlockClient = new ApolloClient({
  uri: 'https://api.goldsky.com/api/public/project_clpx84oel0al201r78jsl0r3i/subgraphs/bartio-blocks/1.0.0/gn',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-first',
    },
    query: {
      fetchPolicy: 'cache-first',
      errorPolicy: 'all',
    },
  },
})

// ---------Berachain Mainnet----------------

export const berachainMainnetClient = new ApolloClient({
  uri: "https://api.goldsky.com/api/public/project_clpx84oel0al201r78jsl0r3i/subgraphs/kodiak-v3-berachain-mainnet/latest/gn",

  cache: new InMemoryCache({
    typePolicies: {
      Token: {
        keyFields: false,
      },
      Pool: {
        keyFields: false,
      },
    },
  }),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

// TODO - add the meta data fetch.
export const berachainMainnetBlockClient = new ApolloClient({
  uri: 'https://api.goldsky.com/api/public/project_clpx84oel0al201r78jsl0r3i/subgraphs/berachain-mainnet-blocks/1.0.0/gn',
  cache: new InMemoryCache(),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-first',
    },
    query: {
      fetchPolicy: 'cache-first',
      errorPolicy: 'all',
    },
  },
})
