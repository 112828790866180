// import BERACHAIN_MAINNET_LIST from "./tokenLists/bera-mainnet.json"

export const UNSUPPORTED_LIST_URLS: string[] = []
export const KODIAK_STATIC_LIST = 'https://static.kodiak.finance/tokenLists/bartio.json'
export const KODIAK_MAINNET_LIST = 'https://static.kodiak.finance/tokenLists/berachain_mainnet.json'
// Create a virtual URL for the local list
// export const BERACHAIN_LIST_URL = 'berachain-mainnet'

// Add a type assertion for the imported JSON
// export const BERACHAIN_LIST: TokenList = BERACHAIN_MAINNET_LIST as TokenList

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
  KODIAK_MAINNET_LIST, // Add local list as highest priority
  KODIAK_STATIC_LIST,
  ...UNSUPPORTED_LIST_URLS, // need to load unsupported tokens as well
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [
  KODIAK_MAINNET_LIST,
  KODIAK_STATIC_LIST,
]
