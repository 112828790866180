import { Token } from '@kodiak-finance/sdk-core'
import { WBERA_ADDRESSES, /*BERA_ADDRESSES */ } from '../constants'

export interface SerializedToken {
  chainId: number
  address: string
  decimals: number
  symbol?: string
  name?: string
}

export function serializeToken(token: Token): SerializedToken {
  return {
    chainId: token.chainId,
    address: token.address,
    decimals: token.decimals,
    symbol: token.symbol,
    name: token.name,
  }
}

export function formatTokenSymbol(address: string, symbol: string) {

  // dumb catch for Celo
  // if (
  //   address === BERA_ADDRESS &&
  //   activeNetwork ===
  //     (BerachainPrivateTestnetNetworkInfo || BerachainArtioTestnetNetworkInfo || BerachainBArtioTestnetNetworkInfo)
  // ) {
  //   return 'BERA'
  // }

  if (WBERA_ADDRESSES.includes(address)) {
    return 'BERA'
  }
  return symbol
}

export function formatTokenName(address: string, name: string) {

  // dumb catch for Celo
  // if (
  //   address === BERA_ADDRESS &&
  //   activeNetwork ===
  //     (BerachainPrivateTestnetNetworkInfo || BerachainArtioTestnetNetworkInfo || BerachainBArtioTestnetNetworkInfo)
  // ) {
  //   return 'BERA'
  // }

  if (WBERA_ADDRESSES.includes(address)) {
    return 'BERA'
  }
  return name
}
